import {
  BooleanField,
  Datagrid,
  FunctionField,
  List,
  ReferenceField,
  TextField,
} from 'react-admin';

const CardProviderList = () => {
  return (
    <List exporter={false}>
      <Datagrid bulkActionButtons={false} rowClick="edit">
        <TextField label="ID" source="id" />
        <TextField label="Name" source="name" />
      </Datagrid>
    </List>
  );
};

export default CardProviderList;

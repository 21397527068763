import {
  BooleanField,
  Datagrid,
  List,
  ListActions,
  TextField,
} from 'react-admin';

const RangeTypeList = () => {
  return (
    <>
      <List exporter={false} actions={<ListActions hasCreate />}>
        <Datagrid bulkActionButtons={false} rowClick="edit">
          <TextField label="Name" source="desc" />

          <TextField label="Min. Amount" source="minAmount" />
          <TextField label="Max. Amount" source="maxAmount" />
          <BooleanField label="Enabled" source="enabled" />
        </Datagrid>
      </List>
    </>
  );
};

export default RangeTypeList;

import {
  Box,
  Button,
  Drawer,
  Fab,
  IconButton,
  Input,
  Stack,
  TextField,
} from '@mui/material';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import { useState } from 'react';
import { Add } from '@mui/icons-material';
import NavigationIcon from '@mui/icons-material/Navigation';

const styles = {
  floatIcon: {
    right: '1.5em',
    bottom: '.2em',
    position: 'fixed',
  },
};
export const Notes = () => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen((o) => !o);
  };
  return (
    <>
      <Fab
        color="primary"
        aria-label="edit"
        sx={styles.floatIcon}
        onClick={toggleDrawer}
      >
        <NoteAltIcon />
      </Fab>
      <Drawer open={open} onClose={toggleDrawer} anchor="right">
        <NotesSection />
      </Drawer>
    </>
  );
};

const NotesSection = () => {
  const [newNote, setNewNote] = useState<boolean | string>(false);

  const addNote = () => {
    setNewNote(false);
  };
  return (
    <Box
      sx={{
        width: '300px',
        padding: '0 1em',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h3>Notes</h3>
        {newNote === false && (
          <Button
            startIcon={<Add />}
            variant="contained"
            onClick={() => setNewNote('')}
          >
            Add
          </Button>
        )}
      </Box>
      {newNote !== false && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1.2,
            button: {
              transform: 'rotate(90deg)',
            },
          }}
        >
          <TextField
            onChange={(e) => setNewNote(e.target.value)}
            multiline
            rows={4}
            placeholder="Enter note details"
            sx={{ flex: 1 }}
          />
          <Fab color="primary" onClick={addNote}>
            <NavigationIcon />
          </Fab>
        </Box>
      )}
    </Box>
  );
};

import polyglotI18nProvider from 'ra-i18n-polyglot';
import {
  Admin,
  CustomRoutes,
  Resource,
  localStorageStore,
  useStore,
  StoreContextProvider,
  combineDataProviders,
} from 'react-admin';
import { Route } from 'react-router';

import authProvider from './authProvider';
import { Dashboard } from './dashboard';
import javaApiDataProvider from './dataProvider';
import nodeApiDataProvider from './dataProvider/node-api';
import englishMessages from './i18n/en';
import { Layout, Login } from './layout';
import orders from './orders';
import billpayOrders from './billpayOrders';
import paymentTypes from './settings/paymentTypes';
import mappings from './settings/mappings';
import settlementTypes from './settings/settlementTypes';
import cardTypes from './settings/cardTypes';
import cardBankTypes from './settings/cardBankTypes';
import merchantCodes from './settings/merchantCodes';
import banks from './settings/banks';
import cardProviders from './settings/cardProviders';
import rangeTypes from './settings/rangeTypes';
import customers from './customers';
import payees from './payees';
import kycs from './kycDocs';
import cards from './cards';
import { themes, ThemeName } from './themes/themes';
import configs from './configs';
import KYCShow from './kycDocs/KYCShow';
import { Notes } from './modules/notes/notes';

const i18nProvider = polyglotI18nProvider(
  (locale) => {
    if (locale === 'fr') {
      return import('./i18n/fr').then((messages) => messages.default);
    }

    // Always fallback on english
    return englishMessages;
  },
  'en',
  [
    { locale: 'en', name: 'English' },
    { locale: 'fr', name: 'Français' },
  ]
);

const store = localStorageStore(undefined, 'paymadi-admin');

const dataProvider = combineDataProviders((resource) => {
  switch (resource) {
    case 'banks':
    case 'cardProviders':
      return nodeApiDataProvider;
    default:
      return javaApiDataProvider;
  }
});

const App = () => {
  const [themeName] = useStore<ThemeName>('themeName', 'soft');
  const lightTheme = themes.find((theme) => theme.name === themeName)?.light;
  const darkTheme = themes.find((theme) => theme.name === themeName)?.dark;
  return (
    <Admin
      title="Paymadi Admin"
      dataProvider={dataProvider}
      store={store}
      authProvider={authProvider}
      dashboard={Dashboard}
      loginPage={Login}
      layout={Layout}
      i18nProvider={i18nProvider}
      disableTelemetry
      lightTheme={lightTheme}
      darkTheme={darkTheme}
      defaultTheme="light"
    >
      <Resource name="customers" {...customers} />
      <Resource name="cards" {...cards} />
      <Resource name="payees" {...payees} />
      <Resource name="orders" {...orders} options={{ label: 'Orders' }} />
      <Resource
        name="billpayOrders"
        {...billpayOrders}
        options={{ label: 'Billpay Orders' }}
      />
      <Resource name="kyc" {...kycs}>
        <Route path=":transactionId" element={<KYCShow />} />
      </Resource>
      <Resource name="paymentTypes" {...paymentTypes} />
      <Resource name="paymentMapping" {...mappings} />
      <Resource name="settlementTypes" {...settlementTypes} />
      <Resource name="cardTypes" {...cardTypes} />
      <Resource name="cardBankTypes" {...cardBankTypes} />
      <Resource name="merchantCodes" {...merchantCodes} />
      <Resource name="banks" {...banks} />
      <Resource name="cardProviders" {...cardProviders} />
      <Resource name="rangeTypes" {...rangeTypes} />
      <Resource name="userConfigs" {...configs} />
    </Admin>
  );
};

const AppWrapper = () => (
  <StoreContextProvider value={store}>
    <App />
    <Notes />
  </StoreContextProvider>
);

export default AppWrapper;

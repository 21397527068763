import * as React from 'react';
import {
  DateField,
  Show,
  Labeled,
  NumberField,
  PrevNextButtons,
  ReferenceField,
  TextField,
  Toolbar,
  useRecordContext,
  useTranslate,
  SaveButton,
  FunctionField,
  Link,
  useShowController,
  useRefresh,
  useNotify,
} from 'react-admin';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Card as MuiCard,
  CardContent,
  Box,
  Grid,
  Typography,
  Stack,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  Button,
} from '@mui/material';

import { Card, Customer } from '../types';
import { renderCardStatus } from './CardList';
import { CustomerDetails } from '../orders/OrderEdit';
import { apiUrl } from '../constants';
import CardImage from './CardImage';

const CardEdit = () => (
  <Show title={<CardTitle />} component="div">
    <CardForm />
  </Show>
);

const CardTitle = () => {
  const translate = useTranslate();
  const record = useRecordContext<Card>();
  return record ? (
    <span>
      {translate('resources.commands.title', {
        reference: record.id,
      })}
    </span>
  ) : null;
};

const CardForm = () => {
  const navigate = useNavigate();
  const { record } = useShowController();
  const refresh = useRefresh();
  const notify = useNotify();
  const [status, setStatus] = React.useState(1);
  const [loading, setLoading] = React.useState(false);

  console.log('record', record);

  const updateCardStatus = React.useCallback(async () => {
    try {
      if (!status) {
        notify('Please select a status', { type: 'error' });
        return;
      }
      setLoading(true);

      const formdata = new FormData();
      // const fileInput = new Blob();
      formdata.append('cardStatusId', status.toString());
      formdata.append('cardFrontImage', new Blob());
      formdata.append('cardBackImage', new Blob());

      // formData.append('')
      const res = await fetch(`${apiUrl}/cards/${record?.id}`, {
        method: 'PUT',
        headers: {
          Authorization: localStorage.getItem('auth') || '',
        },
        body: formdata,
      }).then((res) => res.json());

      if (res.code === 'MSG_0037') {
        notify('Card status was updated successfully', {
          type: 'success',
        });
      } else {
        notify('Something went wrong', { type: 'error' });
      }

      setLoading(false);
      refresh();
      return res;
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  }, [status, record, notify, refresh]);

  const deleteCard = React.useCallback(async () => {
    try {
      const yes = confirm('Are you sure you want to delete this card?');
      if (!yes) return;
      setLoading(true);
      const res = await fetch(`${apiUrl}/cards/${record?.id}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('auth') || '',
        },
      }).then((res) => res.json());

      if (res.code === 'MSG_0037') {
        notify('Card status was deleted successfully', {
          type: 'success',
        });
      } else {
        notify('Something went wrong', { type: 'error' });
      }

      setLoading(false);
      refresh();
      return res;
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  }, [status, record, notify, refresh]);

  return (
    <Stack
      sx={{
        flexDirection: {
          xs: 'column-reverse',
          sm: 'column-reverse',
          md: 'row',
        },
        gap: 2,
      }}
    >
      <Box>
        <PrevNextButtons
          linkType="show"
          sort={{ field: 'createdAt', order: 'DESC' }}
        />
        <MuiCard sx={{ mb: 10 }}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={8}>
                <Typography variant="h6" gutterBottom>
                  Card Details
                </Typography>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6}>
                    <Labeled source="createdAt" label="Submitted On">
                      <DateField
                        source="createdAt"
                        showTime
                        options={{
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                          hour12: true,
                          hour: 'numeric',
                          minute: 'numeric',
                        }}
                      />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Labeled source="id" label="Reference Id">
                      <TextField source="id" />
                    </Labeled>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Labeled source="name" label="Card Holder Name">
                      <TextField source="name" />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Labeled source="last4Digit" label="Last 4 Digits">
                      <TextField source="last4Digit" />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <ReferenceField
                      source="cardType"
                      reference="cardTypes"
                      link={false}
                    >
                      <Labeled source="name" label="Card Type">
                        <TextField source="name" />
                      </Labeled>
                    </ReferenceField>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <ReferenceField
                      source="cardBankType"
                      reference="cardBankTypes"
                      link={false}
                    >
                      <Labeled source="name" label="Card Bank Type">
                        <TextField source="name" />
                      </Labeled>
                    </ReferenceField>
                  </Grid>

                  {record?.frontImageUrl && (
                    <Grid item xs={12} sm={12}>
                      <CardImage
                        input={record?.frontImageUrl}
                        title="Front Image"
                      />
                    </Grid>
                  )}

                  {record?.backImageUrl && (
                    <Grid item xs={12} sm={12}>
                      <CardImage
                        input={record?.backImageUrl}
                        title="Back Image"
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} sm={12}>
                    <Labeled source="cardStatusId">
                      <FunctionField
                        source="cardStatusId"
                        label="Current Status"
                        render={renderCardStatus}
                      />
                    </Labeled>
                  </Grid>
                </Grid>

                <Divider sx={{ my: 1 }} />
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl size="small">
                      <FormLabel id="status">Update Card Status</FormLabel>
                      <RadioGroup
                        aria-labelledby="status"
                        name="status-option"
                        value={status}
                        onChange={(e) => setStatus(parseInt(e.target.value))}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio size="small" />}
                          label="Pending"
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio size="small" />}
                          label="Approved"
                        />
                        <FormControlLabel
                          value="3"
                          control={<Radio size="small" />}
                          label="Rejected"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} mt={2}>
                    <Stack direction="row" spacing={1}>
                      <Button variant="contained" onClick={updateCardStatus}>
                        Update
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={deleteCard}
                      >
                        Delete Card
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Typography variant="h6" gutterBottom>
                  Customer
                </Typography>
                <ReferenceField
                  source="userId"
                  reference="customers"
                  link={false}
                >
                  <CustomerDetails />
                </ReferenceField>
              </Grid>
            </Grid>
          </CardContent>
        </MuiCard>
      </Box>
    </Stack>
  );
};

export default CardEdit;

import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import formatData from './formatData';
import { apiUrl } from '../constants';

export const fetchJson = (url: string, options = {}) => {
  const token = localStorage.getItem('auth') as string;
  return fetchUtils.fetchJson(url, {
    ...options,
    user: {
      authenticated: !!token,
      token,
    },
  });
};

export default {
  getList: (
    resource: string,
    params: {
      pagination: { page: any; perPage: any };
      sort: { field: any; order: any };
      filter: any;
    }
  ) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return fetchJson(url).then(({ headers, json }) => {
      const total = parseInt(
        headers.get('content-range')?.split('/').pop() || '10',
        10
      );
      return {
        data: json.data.map((d: any) => formatData(resource, d)),
        total: total < json.data.length ? json.data.length : total,
      };
    });
  },

  getOne: (resource: string, params: { id: any }) =>
    fetchJson(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: formatData(resource, json.data),
    })),

  getMany: (resource: string, params: { ids: any }) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return fetchJson(url).then(({ json }) => ({
      data: json.data.map((d: any) => formatData(resource, d)),
    }));
  },

  getManyReference: (
    resource: string,
    params: {
      pagination: { page: any; perPage: any };
      sort: { field: any; order: any };
      filter: any;
      target: any;
      id: any;
    }
  ) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return fetchJson(url).then(({ headers, json }) => ({
      data: json.data.map((d: any) => formatData(resource, d)),
      total: parseInt(
        headers.get('content-range')?.split('/').pop() || '10',
        10
      ),
    }));
  },

  update: (resource: string, params: { id: any; data: any }) =>
    fetchJson(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => {
      if (json.code == 'MSG_0050') {
        throw new Error(json.data);
      }
      if (typeof json.data !== 'object') {
        return { data: { id: params.id } };
      }
      return { data: formatData(resource, json.data) };
    }),

  updateMany: (resource: string, params: { ids: any; data: any }) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return fetchJson(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: json.data.map((d: any) => formatData(resource, d)),
    }));
  },

  create: (resource: string, params: { data: any }) =>
    fetchJson(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => {
      if (json.code == 'MSG_0050') {
        throw new Error(json.data);
      }
      return {
        data: formatData(resource, { ...params.data, id: json.data.id }),
      };
    }),

  delete: (resource: string, params: { id: any }) =>
    fetchJson(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: formatData(resource, json.data) })),

  deleteMany: (resource: string, params: { ids: any }) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return fetchJson(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'DELETE',
    }).then(({ json }) => ({
      data: json.data.map((d: any) => formatData(resource, d)),
    }));
  },
};

import * as React from 'react';
import {
  NumberField,
  TextField,
  DateField,
  useTranslate,
  useGetList,
  RecordContextProvider,
  useLocaleState,
  useRecordContext,
  Link,
  useReference,
  FunctionField,
} from 'react-admin';
import {
  Typography,
  Card,
  CardContent,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Grid,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import order from '../orders';
import {
  Order as OrderRecord,
  Review as ReviewRecord,
  Customer,
} from '../types';
import { renderPaymentStatus } from '../orders/OrderList';

const Aside = () => {
  const record = useRecordContext<Customer>();
  if (!record) return null;
  return <EventList />;
};

const EventList = () => {
  const record = useRecordContext<Customer>();
  const translate = useTranslate();

  const { data: orders } = useGetList<OrderRecord>('orders', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'createdAt', order: 'DESC' },
    filter: { userId: record.id },
  });
  // const { data: reviews, total: totalReviews } = useGetList<ReviewRecord>(
  //   'reviews',
  //   {
  //     pagination: { page: 1, perPage: 100 },
  //     sort: { field: 'date', order: 'DESC' },
  //     filter: { customer_id: record.id },
  //   }
  // );
  const reviews: any[] = [];
  const totalReviews = 0;
  const totalOrders = orders?.filter(
    (order) => order.paymentStatus.toUpperCase() !== 'INITIATED'
  ).length;
  const events = mixOrdersAndReviews(orders, reviews);

  return (
    <Box ml={2}>
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {translate('resources.customers.fieldGroups.history')}
          </Typography>
          <Grid container rowSpacing={1} columnSpacing={1}>
            <Grid item xs={6} display="flex" gap={1}>
              <AccessTimeIcon fontSize="small" color="disabled" />
              <Box flexGrow={1}>
                <Typography variant="body2">Registered</Typography>
                <DateField
                  record={record}
                  source="createdAt"
                  showTime
                  options={{
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour12: true,
                    hour: 'numeric',
                    minute: 'numeric',
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={6} display="flex" gap={1}>
              {totalOrders! > 0 && (
                <>
                  <order.icon fontSize="small" color="disabled" />
                  <Link
                    variant="body2"
                    flexGrow={1}
                    to={{
                      pathname: '/orders',
                      search: `displayedFilters=${JSON.stringify({
                        customer_id: true,
                      })}&filter=${JSON.stringify({
                        customer_id: record.id,
                        status: 'delivered',
                      })}`,
                    }}
                  >
                    {translate('resources.commands.amount', {
                      smart_count: totalOrders,
                    })}
                  </Link>
                </>
              )}
            </Grid>
            <Grid item xs={6} display="flex" gap={1}>
              <AccessTimeIcon fontSize="small" color="disabled" />
              <Box flexGrow={1}>
                <Typography variant="body2">Last Seen</Typography>
                <DateField
                  record={record}
                  source="stats.lastSeenAt"
                  showTime
                  options={{
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour12: true,
                    hour: 'numeric',
                    minute: 'numeric',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {events && <Timeline events={events} />}
    </Box>
  );
};

interface AsideEvent {
  type: string;
  date: Date;
  data: OrderRecord | ReviewRecord;
}

const mixOrdersAndReviews = (
  orders?: OrderRecord[],
  reviews?: ReviewRecord[]
): AsideEvent[] => {
  const eventsFromOrders = orders
    ? orders.map<AsideEvent>((order) => ({
        type: 'order',
        date: order.date,
        data: order,
      }))
    : [];
  const eventsFromReviews = reviews
    ? reviews.map<AsideEvent>((review) => ({
        type: 'review',
        date: review.date,
        data: review,
      }))
    : [];
  const events = eventsFromOrders.concat(eventsFromReviews);
  events.sort(
    (e1, e2) => new Date(e2.date).getTime() - new Date(e1.date).getTime()
  );
  return events;
};

const Timeline = ({ events }: { events: AsideEvent[] }) => (
  <Stepper orientation="vertical" sx={{ mt: 1, ml: 1.5, mb: 20 }}>
    {events.map((event) => (
      <Step
        sx={{
          opacity:
            event.data.paymentStatus.toUpperCase() === 'INITIATED' ? 0.4 : 1,
        }}
        key={`${event.type}-${event.data.id}`}
        expanded
        active
        completed
      >
        <Link
          to={`/${event.type === 'order' ? 'orders' : 'reviews'}/${
            event.data.id
          }`}
        >
          <RecordContextProvider value={event.data}>
            <StepLabel icon={<order.icon color="disabled" sx={{ pl: 0.5 }} />}>
              {/* {event.type === 'order' ? <OrderTitle /> : <ReviewTitle />} */}
              <Typography variant="body1" color="textPrimary">
                <NumberField
                  source="amount"
                  options={{ style: 'currency', currency: 'INR' }}
                />
              </Typography>
            </StepLabel>
            <StepContent>
              <Order />
            </StepContent>
          </RecordContextProvider>
        </Link>
      </Step>
    ))}
  </Stepper>
);

const OrderTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  if (!record) return null;
  return (
    <>
      {translate('pos.events.order.title', {
        // smart_count: record.basket.length,
        smart_count: 1,
      })}
    </>
  );
};

const Order = () => {
  const record = useRecordContext();
  const [locale] = useLocaleState();
  if (!record) return null;
  return (
    <>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        {new Date(record.createdAt).toLocaleString(locale, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })}
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        <FunctionField source="paymentStatus" render={renderPaymentStatus} />
      </Typography>
      <NumberField
        gutterBottom
        variant="h6"
        color="textSecondary"
        source="priceBreakUp.settlementAmount"
        options={{ style: 'currency', currency: 'INR' }}
      />
      <Typography variant="body2" color="textSecondary">
        <TextField source="settlementType" />
        &nbsp;-&nbsp;
        <TextField source="serviceFee" />
      </Typography>
    </>
  );
};

export default Aside;

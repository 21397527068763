import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import groupBy from 'lodash/groupBy';
import uniq from 'lodash/uniq';
import { mids } from '../../dataProvider/formatData';
import { List, ListItem } from '@mui/material';
import { SettlementTypeMap } from '../../types';
import { PaymentTypes, SettlementTypes } from './MappingsList';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const MappingListPreview = ({ paymentMapping }: { paymentMapping: any }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const grouped: Record<string, any> = {
    INSTANT_PAY: [],
    FAST_PAY: [],
    REGULAR_PAY: [],
  };

  const groupedBySettlement = groupBy(
    paymentMapping,
    (item: any) => item.settlementType
  );

  Object.keys(grouped).forEach((key) => {
    grouped[key] = groupBy(
      groupedBySettlement[key],
      (item: any) => item.paymentType
    );

    Object.keys(grouped[key]).forEach((paymentType) => {
      grouped[key][paymentType] = groupBy(
        grouped[key][paymentType],
        (item: any) => item.cardType
      );

      Object.keys(grouped[key][paymentType]).forEach((cardType) => {
        grouped[key][paymentType][cardType] = groupBy(
          grouped[key][paymentType][cardType],
          (item: any) => item.cardBankType
        );

        Object.keys(grouped[key][paymentType][cardType]).forEach(
          (cardBankType) => {
            grouped[key][paymentType][cardType][cardBankType] =
              grouped[key][paymentType][cardType][cardBankType][0].merchantCode;
          }
        );

        if (
          uniq(Object.values(grouped[key][paymentType][cardType])).length === 1
        ) {
          grouped[key][paymentType][cardType] =
            mids[
              Object.values(grouped[key][paymentType][cardType])[0] as string
            ]?.details || Object.values(grouped[key][paymentType][cardType])[0];
        }
      });

      if (uniq(Object.values(grouped[key][paymentType])).length === 1) {
        grouped[key][paymentType] = Object.values(grouped[key][paymentType])[0];
      }
    });

    if (uniq(Object.values(grouped[key])).length === 1) {
      grouped[key] = Object.values(grouped[key])[0];
    }
  });

  const renderMenu = (grouped: Record<string, any>) => {
    return Object.keys(grouped).map((settlementType) => {
      if (typeof grouped[settlementType] === 'string') {
        return (
          <TableRow>
            <TableCell>{SettlementTypes[settlementType]}</TableCell>
            <TableCell>All</TableCell>
            <TableCell>{grouped[settlementType]}</TableCell>
          </TableRow>
        );
      }

      return Object.keys(grouped[settlementType]).map((paymentType) => {
        if (typeof grouped[settlementType][paymentType] === 'string') {
          return (
            <TableRow>
              <TableCell>{SettlementTypes[settlementType]}</TableCell>
              <TableCell>{PaymentTypes[paymentType]}</TableCell>
              <TableCell>{grouped[settlementType][paymentType]}</TableCell>
            </TableRow>
          );
        }

        return Object.keys(grouped[settlementType][paymentType]).map(
          (cardType) => {
            if (
              typeof grouped[settlementType][paymentType][cardType] === 'string'
            ) {
              return (
                <TableRow>
                  <TableCell>{SettlementTypes[settlementType]}</TableCell>
                  <TableCell>{PaymentTypes[paymentType]}</TableCell>
                  <TableCell>{cardType}</TableCell>
                  <TableCell>
                    {grouped[settlementType][paymentType][cardType]}
                  </TableCell>
                </TableRow>
              );
            }

            return Object.keys(
              grouped[settlementType][paymentType][cardType]
            ).map((cardBankType) => (
              <TableRow>
                <TableCell>{SettlementTypes[settlementType]}</TableCell>
                <TableCell>{PaymentTypes[paymentType]}</TableCell>
                <TableCell>{cardType}</TableCell>
                <TableCell>{cardBankType}</TableCell>
                <TableCell>
                  {grouped[settlementType][paymentType][cardType][cardBankType]}
                </TableCell>
              </TableRow>
            ));
          }
        );
      });
    });
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={<VisibilityIcon />}
      >
        Preview
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Table aria-label="simple table" size="small">
          <TableBody>{renderMenu(grouped)}</TableBody>
        </Table>
      </Menu>
    </div>
  );
};

export default MappingListPreview;

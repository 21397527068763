import { useState, useEffect, useCallback } from 'react';
import { apiUrl } from '../constants';
import useLocalStorage from 'use-local-storage';

const useNodalBalance = () => {
  const [balance, setBalance] = useLocalStorage<any>('nodalBalance', null);

  const [balanceLoading, setBalanceLoading] = useState(false);
  const refetch = useCallback(async () => {
    try {
      setBalanceLoading(true);
      const res = await fetch(`${apiUrl}/axisbank/balance`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('auth') || '',
        },
      }).then((res) => res.json());

      setBalance(res.data);
      setBalanceLoading(false);
    } catch (e) {
      console.error(e);
      setBalanceLoading(false);
    }
  }, []);
  useEffect(() => {
    if (balance) return;
    refetch();
  }, [refetch, balance]);

  return { balance, balanceLoading, refetch };
};

export default useNodalBalance;
